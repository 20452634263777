import { ImportMap } from './legacy/type/define';
import { config as initConfig } from './legacy/customer/base/default/init.conf';

let legacyContextBaseCustomer;
let legacyContextCustomer;
let legacyContextBaseCustomerKeys;

const legacyContextDefault = (import.meta as any).webpackContext(`bundle-loader!./legacy/default`, {
	recursive: true,
	regExp: /^(?!.*\.d\.ts$).*\.(js|ts)/,
});

if (true) {
	legacyContextCustomer = (import.meta as any).webpackContext(`bundle-loader!./legacy/customer/base/default`, {
		recursive: true,
		regExp: /^(?!.*\.d\.ts$).*\.(js|ts)/,
	});
	legacyContextBaseCustomer = (import.meta as any).webpackContext(`bundle-loader!./legacy/customer/base/${ <string>'dkr' }`, {
		recursive: true,
		regExp: /^(?!.*\.d\.ts$).*\.(js|ts)/,
	});
	legacyContextBaseCustomerKeys = legacyContextBaseCustomer.keys();
} else {
	legacyContextCustomer = (import.meta as any).webpackContext(`bundle-loader!./legacy/customer/${ <string>'dkr' }`, {
		recursive: true,
		regExp: /^(?!.*\.d\.ts$).*\.(js|ts)/,
	});
}

const legacyContextDefaultKeys = legacyContextDefault.keys();
const legacyContextCustomerKeys = legacyContextCustomer.keys();

const requireAlias = (<any>{"framework":"legacy/default","lib":"legacy/default/lib","helper":"legacy/default/helper","plugins":"legacy/default/plugins","misc":"legacy/default/misc","handler":"legacy/default/handler","baseCustomer":"legacy/customer/base/dkr","customer":"legacy/customer/base/default"});

export function legacyRequire(
	path: keyof ImportMap,
	callback?: ((plugin: any) => void) | boolean,
) {
	legacyContext(resolvePath(path))
		.then(plugin => {
			if (typeof callback === 'function') {
				callback(plugin);
			}
		});
}

function legacyContext(path: string): Promise<any> {
	const frameworkAliasNoLegacy = requireAlias.framework.replace('legacy', '');
	const defaultPath = path.replace(frameworkAliasNoLegacy, '');
	const defaultPathWithJS = `${ defaultPath }.js`;
	const defaultPathWithTS = `${ defaultPath }.ts`;
	if (legacyContextDefaultKeys.indexOf(defaultPathWithJS) !== -1) {
		return import(`legacy/${ frameworkAliasNoLegacy.substring(1) }${ defaultPath.substring(1) }.js`);
	}
	if (legacyContextDefaultKeys.indexOf(defaultPathWithTS) !== -1) {
		return import(`legacy/${ frameworkAliasNoLegacy.substring(1) }${ defaultPath.substring(1) }.ts`);
	}

	if (true) {
		const baseAliasNoLegacy = requireAlias.baseCustomer.replace('legacy', '');
		const basePath = path.replace(baseAliasNoLegacy, '');
		const basePathWithJS = `${ basePath }.js`;
		const basePathWithTS = `${ basePath }.ts`;
		if (legacyContextBaseCustomerKeys.indexOf(basePathWithJS) !== -1) {
			return import(`legacy/${ baseAliasNoLegacy.substring(1) }${ basePath.substring(1) }.js`);
		}
		if (legacyContextBaseCustomerKeys.indexOf(basePathWithTS) !== -1) {
			return import(`legacy/${ baseAliasNoLegacy.substring(1) }${ basePath.substring(1) }.ts`);
		}
	}
	const customerAliasNoLegacy = requireAlias.customer.replace('legacy', '');
	const customerPath = path.replace(customerAliasNoLegacy, '');
	const customerPathWithJS = `${ customerPath }.js`;
	const customerPathWithTS = `${ customerPath }.ts`;
	if (legacyContextCustomerKeys.indexOf(customerPathWithJS) !== -1) {
		return import(`legacy/${ customerAliasNoLegacy.substring(1) }${ customerPath.substring(1) }.js`);
	}
	if (legacyContextCustomerKeys.indexOf(customerPathWithTS) !== -1) {
		return import(`legacy/${ customerAliasNoLegacy.substring(1) }${ customerPath.substring(1) }.ts`);
	}
	throw new Error(`${ path } is not in legacy-directory`);
}

function resolvePath(path: string): string {
	const overrideRule = initConfig.override[path];
	if (overrideRule) {
		path = overrideRule;
	}
	const indexOfSlash = path.indexOf('/');

	if (indexOfSlash === -1) {
		throw new Error(`Error on path ${ path }. Requested Plugin with relative path. Only use legacy-aliases from webpack.alias.js`);
	}
	const alias = path.substr(0, indexOfSlash);
	const pluginPath = path.substr(indexOfSlash);
	let resolvedAlias = requireAlias[alias];

	if (!resolvedAlias) {
		throw new Error(`Alias: ${ alias } is not a known alias. Provide in webpack.alias.js`);
	}
	resolvedAlias = resolvedAlias.replace(`legacy/`, '');

	return `./${ resolvedAlias }${ pluginPath }`;
}
